
















import RoleServices from '@/services/role';
import { Vue, Component, ModelSync } from 'vue-property-decorator';
@Component
export default class KTagInput extends Vue {
  @ModelSync('value', 'change', { type: String })
  user!: string;

  roleSrv = new RoleServices();
  userList: any[] = [];

  set userArr(value: string[]) {
    let userIdArr: string[] = [];
    let userNameArr: string[] = [];
    value.forEach((v: string) => {
      let [userId, userName] = v.split('/');
      userIdArr.push(userId);
      userNameArr.push(userName);
    });
    let idString = userIdArr.join(',');
    let nameString = userNameArr.join(',');
    if (idString && nameString) {
      this.$emit('change', `${idString}/${nameString}`);
    } else {
      this.$emit('change', '');
    }
  }

  get userArr(): string[] {
    if (!this.user) return [];
    let [id, name] = this.user.split('/');
    let idArr = id.split(',');
    let nameArr = name.split(',');
    return idArr.map((v: string, k: number) => `${v}/${nameArr[k]}`);
  }

  onSelect(value: string[]) {
    this.userArr = value;
    let userIdArr: string[] = [];
    let userNameArr: string[] = [];
    value.forEach((v: string) => {
      let [userId, userName] = v.split('/');
      userIdArr.push(userId);
      userNameArr.push(userName);
    });
    this.$emit('select', { userId: userIdArr.join(','), userName: userNameArr.join(',') });
  }

  async handleSearch(value: string) {
    if (!value) return false;
    this.userList = await this.roleSrv.searchRoleUserList({ userId_like: value });
  }
}
