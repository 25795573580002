































































import MenuMixin from '@/mixins/menu-mixin';
import { mixins } from 'vue-class-component';
import { Component, PropSync, Watch, Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';
@Component
export default class KCatalogTree extends mixins(MenuMixin) {
  @PropSync('list', { type: Array }) syncedList!: any[];
  @Prop({ default: false }) min!: boolean;

  expand: boolean = true;
  activedKey: string = '';

  created() {
    this.activedKey = this.$route.fullPath;
  }

  @Watch('$route')
  onRouteChange(to: Route) {
    this.activedKey = to.fullPath;
  }

  command(key: string, record: any) {
    this.$emit('command', key, record);
  }

  expend(index: number, info: any) {
    this.syncedList[index].expand = !this.syncedList[index].expand;
    if (!this.syncedList[index].expand) return false;
    this.$emit('command', 'expandFolder', info);
  }
}
