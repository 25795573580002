






















import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { Mutation, State } from 'vuex-class';
@Component
export default class Left extends Vue {
  @PropSync('menu') menuSync!: any[];
  @Mutation SWITCH_MENU_INDENT: any;
  @State MENU_INDENT: any;

  get defaultOpenKeys() {
    return this.menuSync.map((v, index) => index);
  }

  onMenuClick(e: { key: string }) {
    let { key } = e;
    this.$router.push(key);
  }

  selectedKeys: string[] = [];
  created() {
    this.selectedKeys = [this.$route.fullPath];
  }

  @Watch('$route')
  onRouteChange(to: Route) {
    this.selectedKeys = [to.fullPath];
  }
}
