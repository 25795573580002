<template>
  <div class="noBreak">
    <a-select placeholder="请选择" style="width: 140px" v-model="period">
      <a-select-option v-for="(item, k) of periodType" :key="k" :value="item">{{ item }}</a-select-option>
    </a-select>
    <template v-if="period != '每日'">
      <span class="mgl10 mgr10">第</span>
      <a-input-number placeholder="请输入" style="width: 120px" v-model="val" />
      <span class="mgl10 mgr10">天</span>
    </template>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String | Number | Boolean | Array | Object,
      default: () => {}
    }
  },
  data() {
    return {
      periodType: ['每日', '每周', '每月', '每季', '每年'],
      period: '',
      val: ''
    };
  },
  created() {
    this.initVal();
  },
  computed: {
    inputValue: {
      get() {
        this.initVal();
        return this.value;
      },
      set(value) {
        this.$emit('change', value);
      }
    }
  },
  watch: {
    period() {
      this.inputValue = this.period + '/' + this.val;
    },
    val() {
      this.inputValue = this.period + '/' + this.val;
    }
  },
  methods: {
    initVal() {
      let [period, val] = (this.value || '').split('/');
      this.period = period || '';
      this.val = val || '';
    }
  }
};
</script>
<style lang="less">
.noBreak {
  // height: 32px;
  overflow: hidden;
}
</style>
