import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
@Component
export default class MenuMixin extends Vue {
  @State('ROLE_MENU') ROLE_MENU: any;

  MENU(code: string) {
    return this.ROLE_MENU.includes(code);
  }

  DISABLE(code: string) {
    return !this.ROLE_MENU.includes(code);
  }

  SHOWKEY(code?: string) {
    return code ? this.ROLE_MENU.includes(code) : true;
  }
}
