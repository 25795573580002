import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Modal } from 'ant-design-vue';
import router from '@/router';
interface Tab {
  title: string;
  path: string;
  fullPath: string;
  fromPath: string;
}
const changTab = (fullPath: string, activeTabs: Tab[]) => {
  let length = activeTabs.length;
  if (length != 0) {
    let path = activeTabs[length - 1].fullPath;
    router.push(path);
  } else {
    // 最后一个标签页取消或者删除后跳转到其父级页
    let parent: string = fullPath
      .split('/')
      .filter((v: string, index: number) => index < 3)
      .join('/');
    router.push(parent);
  }
};
@Module({ namespaced: true })
export default class System extends VuexModule {
  leftMenuIndent: boolean = false;
  activeTabs: Tab[] = [];

  @Mutation
  setLeftMenuIndent() {
    this.leftMenuIndent = !this.leftMenuIndent;
  }

  @Mutation
  setActiveTabs(tab: Tab) {
    let blanks = ['/setting', '/handle', '/manage'];
    if (blanks.includes(tab.path)) return false;
    let index = this.activeTabs.findIndex(v => v.path === tab.path);
    if (index > -1) {
      this.activeTabs[index] = tab;
    } else {
      this.activeTabs.push(tab);
    }
  }

  @Mutation
  removeActiveTabs(fullPath: string) {
    let index = this.activeTabs.findIndex(v => v.fullPath === fullPath);
    if (/\/(edit|add)/.test(fullPath)) {
      Modal.confirm({
        title: '提示',
        content: '当前页面数据未保存，确定要离开？',
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          this.activeTabs.splice(index, 1);
          changTab(fullPath, this.activeTabs);
        },
        onCancel: async () => false
      });
    } else {
      this.activeTabs.splice(index, 1);
      changTab(fullPath, this.activeTabs);
    }
  }

  @Mutation
  removeTabsWithoutConfirm(fullPath: string) {
    let index = this.activeTabs.findIndex(v => v.fullPath === fullPath);
    this.activeTabs.splice(index, 1);
    changTab(fullPath, this.activeTabs);
  }

  @Mutation
  replaceActiveTabs(params: { toPath: string; fromPath: string }) {
    let { toPath, fromPath } = params;
    let index = this.activeTabs.findIndex(v => v.fullPath === fromPath);
    if (/\/(edit|add)/.test(fromPath)) {
      Modal.confirm({
        title: '提示',
        content: '当前页面数据未保存，确定要离开？',
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          this.activeTabs.splice(index, 1);
          router.replace(toPath);
        },
        onCancel: async () => false
      });
    } else {
      this.activeTabs.splice(index, 1);
      router.replace(toPath);
    }
  }

  @Mutation
  closeFormTabs(fullPath: string) {
    let index = this.activeTabs.findIndex(v => v.fullPath === fullPath);
    this.activeTabs.splice(index, 1);
  }

  @Mutation
  closeOtherTabs(fullPath: string) {
    this.activeTabs = this.activeTabs.filter(v => v.fullPath === fullPath);
  }

  @Mutation
  closeAllTabs() {
    this.activeTabs = [];
  }
}
