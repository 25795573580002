import moment from 'moment';
const format = 'YYYY-MM-DD';

export const getRange = (e: string) => {
  let start = '';
  switch (e) {
    case 'today':
      start = moment().format(format);
      break;
    case 'week':
      start = moment()
        .startOf('week')
        .format(format);
      break;
    case 'month':
      start = moment()
        .startOf('month')
        .format(format);
      break;
    case 'year':
      start = moment()
        .startOf('year')
        .format(format);
      break;
    case 'total':
      start = moment('2021-01-01').format(format);
      break;
    default:
      start = moment('2021-01-01').format(format);
      break;
  }
  let end = moment().format(format);
  return [start, end];
};

export const transRange = (dateRange: string[]) => {
  let startTime = moment(dateRange[0]).format('YYYY-MM-DD');
  let endTime = moment(dateRange[1]).format('YYYY-MM-DD');
  return [startTime, endTime];
};

export const getSequence = (): string => {
  let date = moment().format('YYYYMMDD');
  let sequence = Math.round(Math.random() * 10000000)
    .toString()
    .padStart(8, '0');
  return date + sequence;
};
