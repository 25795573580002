import http from './axios';

export interface RoleDto {
  id?: number;
  roleCode?: string;
  roleDescription?: string;
  roleKind?: string;
  roleModule?: string;
  roleName?: string;
  roleRefDept?: string;
  roleRefName?: string;
  roleSort?: number;
  status?: number;
}

export default class RoleServices {
  private readonly list_api = '/v1/roles/list';
  private readonly category_api = '/v1/roles/category';
  private readonly info_api = '/v1/roles/info';
  private readonly save_api = '/v1/roles/save';
  private readonly savelist_api = '/v1/roles/savelist';
  private readonly update_api = '/v1/roles/update';
  private readonly remove_api = '/v1/roles/delete';
  private readonly add_roleuser_api = '/v1/sysroleuser/savelist';
  private readonly list_roleuser_api = '/v1/sysroleuser/list';
  private readonly remove_roleuser_api = '/v1/sysroleuser/delete';
  private readonly role_menu_api = '/v1/permission/perms';
  private readonly user_api = '/v1/sys/user/byCondition';
  private readonly login_api = '/v1/sysroleuser/login';
  private readonly search_user_api = '/v1/sysroleuser/distinct-user';

  constructor() {}

  async getList(params?: object): Promise<any> {
    let res: any = await http.get(this.list_api, { params: params });
    return res.payload;
  }

  async getUser(params?: object): Promise<any> {
    let res: any = await http.get(this.user_api, { params: params });
    return res.payload;
  }

  async getMenu(params?: object): Promise<any> {
    let res: any = await http.get(this.role_menu_api, { params: params });
    return res.payload;
  }

  async getRoleUserList(params?: object): Promise<any> {
    let res: any = await http.get(this.list_roleuser_api, { params: params });
    return res.payload;
  }

  async searchRoleUserList(params?: object): Promise<any> {
    let res: any = await http.get(this.search_user_api, { params: params });
    return res.payload;
  }

  async getCategory(module?: string): Promise<any> {
    let res: any = await http.get(this.category_api + '/' + module);
    return res.payload;
  }

  async getInfo(id: string | number) {
    let res: any = await http.get(this.info_api + '/' + id);
    return res.payload;
  }

  async add(data: RoleDto) {
    const params = { ...data, roleCode: 'ROLE_' + data.roleCode };
    let res: any = await http.post(this.save_api, params);
    return res.header?.code == 200 ? res.payload : false;
  }

  async addRoleList(module: string, params: any) {
    let res: any = await http.post(this.savelist_api + '/' + module, params);
    return res.header?.code == 200 ? true : false;
  }

  async update(data: RoleDto): Promise<boolean> {
    let res: any = await http.post(this.update_api, data);
    return res.header?.code == 200;
  }

  async remove(id: string | number) {
    let res: any = await http.post(this.remove_api, [id]);
    return res.header?.code == 200;
  }

  async removeRoleUser(ids: number[]) {
    let res: any = await http.post(this.remove_roleuser_api, ids);
    return res.header?.code == 200;
  }

  async addRoleUser(params: any) {
    let res: any = await http.post(this.add_roleuser_api, params);
    return res.header?.code == 200 ? true : false;
  }
}
