











































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { FilterItem } from '@/interfaces/filter-items';
@Component
export default class KFilterOnly extends Vue {
  @Prop() title!: string;
  @Prop() items!: FilterItem[];
  @Watch('items')
  onItemsChange() {
    this.computedWidth();
  }

  filter = {};

  visible: boolean = false;

  created() {
    this.computedWidth();
  }

  width: number = 66;
  computedWidth() {
    let maxLength: number = 0;
    this.items.forEach(v => {
      maxLength = maxLength < v.label.length ? v.label.length : maxLength;
    });
    this.width = maxLength * 14;
  }

  excute() {
    this.$emit('excute', this.filter);
  }

  reset() {
    this.filter = {};
    this.$emit('reset');
  }
}
