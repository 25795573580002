import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { message } from 'ant-design-vue';

const http = axios.create({
  baseURL: '/apis',
  withCredentials: true,
  timeout: 120000,
  headers: {
    'Cache-Control': 'no-cache',
    Authorization:
      'Bearer eyJhbGciOiJIUzUxMiJ9.eyJ0b2tlbl9jcmVhdGVfdGltZSI6MTYxODM4ODg3MTMwNCwic3ViIjoiYWRtaW4iLCJ0b2tlbl91c2VyX25hbWUiOiJhZG1pbiIsImV4cCI6ODY0MDAsInRva2VuX3VzZXJfcGFzc3dvcmQiOiIkMmEkMTAkWThWdFcwd2tFMHRKVk1kd05JSkhYZXk3TGt5WTVKU25rQTZtS0poajNrSDkuc0tHTk1PQzYifQ.7cO6PmZLqxX1bRC_17yJjrBDUi5lNs5Xe-rb8wt2F1L0B73LEEgi8-EASvg_-Y9YrpgSmVoqDljNVT6LOEGaqQ'
  }
});

http.interceptors.request.use((config: AxiosRequestConfig) => {
  return config;
});

const whitelist = ['/v1/sources/precheck_sql', '/v1/subtabledef/excute-sql'];

http.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    return response.data;
  },
  error => {
    let res = error.response;
    if (res) {
      let msg = res.data.header?.msg;
      let url = res.config.url;
      if (whitelist.includes(url)) {
        return res.data;
      }
      if (res.data.code == 401) {
        // 鉴权失败执行操作
      }
      message.error(msg ? msg : 'Server Error!');
      return false;
    } else {
      message.error('服务异常，无法访问');
      return false;
    }
  }
);

export default http;
