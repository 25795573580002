














import { Vue, Component, PropSync } from 'vue-property-decorator';
interface BreadItem {
  name: string;
  path?: string;
}
@Component
export default class KBreadCrumb extends Vue {
  @PropSync('list', { type: Array }) syncedList!: BreadItem[];

  redirect(path: string | null) {
    if (!path) return;
    this.$router.push(path);
  }
}
