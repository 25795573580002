<template>
  <div>
    <a-tooltip>
      <template slot="title" v-if="tip">{{ tip }}</template>
      <a-range-picker :placeholder="['开始日期', '结束日期']" v-model="dateValue" @change="change" valueFormat="YYYY-MM-DD"/>
    </a-tooltip>
    <span class="mgr20"></span>
    <a-radio-group v-model="radioDate">
      <a-radio-button value="week">本周</a-radio-button>
      <a-radio-button value="month">本月</a-radio-button>
      <a-radio-button value="total">累计</a-radio-button>
    </a-radio-group>
  </div>
</template>
<script>
import { getRange, transRange } from '@/libs/time';
export default {
  model: {
    prop: 'date',
    event: 'change'
  },
  props: {
    tip: {
      type: String
    },
    date: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      radioDate: 'week'
    };
  },
  created() {
    this.chooseRadio(this.date);
  },
  watch: {
    radioDate(n) {
      if (n != 'week' && n != 'month' && n != 'total') return false;
      this.dateValue = getRange(n);
    },
    dateValue(n) {
      this.chooseRadio(n);
    }
  },
  computed: {
    dateValue: {
      get() {
        return this.date;
      },
      set(value) {
        this.$emit('change', transRange(value));
      }
    }
  },
  methods: {
    change() {
      //   this.$emit('change', this.dateValue);
    },
    chooseRadio(n) {
      let weekDate = getRange('week');
      let monthDate = getRange('month');
      let totalDate = getRange('total');
      let [start, end] = n;
      if (start == weekDate[0]) this.radioDate = 'week';
      if (start == monthDate[0]) this.radioDate = 'month';
      if (start == totalDate[0]) this.radioDate = 'total';
      if (end != weekDate[1]) {
        this.radioDate = '';
      }
    }
  }
};
</script>
<style lang="less">
.k-tag-input {
  .ant-tag {
    padding: 4px 10px 5px;
  }
}
</style>
