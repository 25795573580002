







import { Vue, Component, ModelSync } from 'vue-property-decorator';
@Component
export default class KTagInput extends Vue {
  @ModelSync('tag', 'change', { type: Array })
  tags!: string[];

  inputVisible = false;
  inputValue = '';
  showInput() {
    this.inputVisible = true;
    this.$nextTick(function() {
      let dom: any = this.$refs.input;
      dom.focus();
    });
  }

  addTag() {
    const inputValue = this.inputValue;
    let tags = this.tags;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      this.tags.push(inputValue);
    }
    this.inputVisible = false;
    this.inputValue = '';
  }

  removeTag(index: number) {
    this.tags.splice(index, 1);
  }
}
