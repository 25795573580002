import Vue from 'vue';
import Vuex from 'vuex';
import System from './system';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    SYS_DICT: {},
    LOADING: false,
    ROLE_MENU: [],
    BLANK_PAGE: ['/setting', '/handle', '/manage'], // 默认空白页
    MENU_INDENT: false,
    RELOAD_DICK_MENU: 0,
    RELOAD_PARAM_MENU: 0,
    RELOAD_DIM_MENU: 0,
    RELOAD_SUB_MENU: 0,
    RELOAD_RULE_MENU: 0,
    RELOAD_IND_MENU: 0,
    RELOAD_ROLE_DETAIL: 0,
    RELOAD_DS_MENU: 0
  },
  mutations: {
    CHANGE_LOADING(state, loading) {
      state.LOADING = loading;
    },
    SET_SYS_DICT(state, dict) {
      state.SYS_DICT = dict;
    },
    SWITCH_MENU_INDENT(state) {
      state.MENU_INDENT = !state.MENU_INDENT;
    },
    SET_ROLE_MENU(state, dict) {
      state.ROLE_MENU = dict;
    },
    HANDLE_RELOAD_DICK_MENU(state) {
      state.RELOAD_DICK_MENU++;
    },
    HANDLE_RELOAD_PARAM_MENU(state) {
      state.RELOAD_PARAM_MENU++;
    },
    HANDLE_RELOAD_DIM_MENU(state) {
      state.RELOAD_DIM_MENU++;
    },
    HANDLE_RELOAD_SUB_MENU(state) {
      state.RELOAD_SUB_MENU++;
    },
    HANDLE_RELOAD_RULE_MENU(state) {
      state.RELOAD_RULE_MENU++;
    },
    HANDLE_RELOAD_IND_MENU(state) {
      state.RELOAD_IND_MENU++;
    },
    HANDLE_RELOAD_ROLE_DETAIL(state) {
      state.RELOAD_ROLE_DETAIL++;
    },
    HANDLE_RELOAD_DS_MENU(state) {
      state.RELOAD_DS_MENU++;
    }
  },
  actions: {},
  modules: {
    System
  }
});
