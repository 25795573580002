


































import MenuMixin from '@/mixins/menu-mixin';
import { mixins } from 'vue-class-component';
import { Component, PropSync, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
@Component
export default class KCatalog extends mixins(MenuMixin) {
  @PropSync('list', { type: Array }) syncedList!: any[];

  expand: boolean = true;
  activedKey: string = '';

  created() {
    this.activedKey = this.$route.fullPath;
  }

  @Watch('$route')
  onRouteChange(to: Route) {
    this.activedKey = to.fullPath;
  }

  update(key: string) {
    this.$emit('update', key);
  }

  expend(index: number, info: any) {
    this.syncedList[index].expand = !this.syncedList[index].expand;
    if (!this.syncedList[index].expand) return false;
    // this.$emit('update', `expand:${info.id || 0}/${index}`);
  }
}
