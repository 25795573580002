import { RouteConfig } from 'vue-router';

export const handle: RouteConfig = {
  path: '/handle',
  component: () => import('../views/handle/handle.vue'),
  meta: {
    title: '结算清分中心',
    breadCrumb: [{ name: '结算提报中心' }]
  },
  children: [
    {
      path: '/handle/review',
      meta: {
        title: '清分工作台',
        breadCrumb: [{ name: '结算清分中心' }, { name: '清分工作台' }]
      },
      component: () => import('../views/handle/review/review.vue')
    }
  ]
};

export const setting: RouteConfig = {
  path: '/setting',
  component: () => import('../views/setting/setting.vue'),
  meta: {
    title: '模板配置中心',
    breadCrumb: [{ name: '模板配置中心' }]
  },
  children: [
    {
      path: '/setting/send',
      meta: {
        title: '配置工作台',
        breadCrumb: [{ name: '模板配置中心' }, { name: '配置工作台' }]
      },
      component: () => import('../views/setting/send/send.vue')
    },
    {
      path: '/setting/review',
      meta: {
        title: '审批工作台',
        breadCrumb: [{ name: '模板配置中心' }, { name: '审批工作台' }]
      },
      component: () => import('../views/setting/review/review.vue')
    },
    {
      path: '/setting/model',
      meta: {
        title: '结算模型管理',
        breadCrumb: [{ name: '模板配置中心' }, { name: '结算模型管理' }]
      },
      component: () => import('../views/setting/model/model.vue')
    },
    {
      path: '/model-form',
      meta: {
        title: '新建结算模型',
        breadCrumb: [{ name: '模板配置中心' }, { name: '结算模型管理' }, { name: '新建结算模型' }]
      },
      component: () => import('../views/setting/model/model-form.vue')
    },
    {
      path: '/model-detail',
      meta: {
        title: '结算模型详情',
        breadCrumb: [{ name: '模板配置中心' }, { name: '结算模型管理' }, { name: '结算模型详情' }]
      },
      component: () => import('../views/setting/model/model-detail.vue')
    },
    {
      path: '/setting/interface',
      meta: {
        title: '接口管理',
        breadCrumb: [{ name: '模板配置中心' }, { name: '接口管理' }]
      },
      component: () => import('../views/setting/interface/interface.vue')
    },
    {
      path: '/interface-form',
      meta: {
        title: '文件接口管理',
        breadCrumb: [{ name: '模板配置中心' }, { name: '接口管理' }, { name: '文件接口管理' }]
      },
      component: () => import('../views/setting/interface/interface-form.vue')
    },
    {
      path: '/setting/dict',
      meta: {
        title: '字典集管理',
        breadCrumb: [{ name: '模板配置中心' }, { name: '字典集管理' }]
      },
      component: () => import('../views/setting/dict/dict.vue')
    },
    {
      path: '/dict-form',
      meta: {
        title: '新建字典',
        breadCrumb: [{ name: '模板配置中心' }, { name: '字典集管理' }, { name: '新建字典' }]
      },
      component: () => import('../views/setting/dict/dict-form.vue')
    },
    {
      path: '/dict-detail',
      meta: {
        title: '字典详情',
        breadCrumb: [{ name: '模板配置中心' }, { name: '字典集管理' }, { name: '字典详情' }]
      },
      component: () => import('../views/setting/dict/dict-detail.vue')
    },
    {
      path: '/setting/sheet',
      meta: {
        title: '结算单模板',
        breadCrumb: [{ name: '模板配置中心' }, { name: '结算单模板' }]
      },
      component: () => import('../views/setting/sheet/sheet.vue')
    }
  ]
};

export const manage: RouteConfig = {
  path: '/manage',
  component: () => import('../views/manage/manage.vue'),
  meta: {
    title: '平台管理',
    breadCrumb: [{ name: '平台管理' }]
  },
  children: [
    {
      path: '/manage/permision',
      meta: {
        title: '系统授权管理',
        breadCrumb: [{ name: '平台管理' }, { name: '系统授权管理' }]
      },
      component: () => import('../views/manage/permision/permision.vue'),
      children: [
        {
          path: '/manage/permision/detail',
          meta: {
            title: '角色详情',
            breadCrumb: [{ name: '平台管理' }, { name: '系统授权管理' }, { name: '角色详情' }]
          },
          component: () => import('../views/manage/permision/permision-detail.vue')
        }
      ]
    },
    {
      path: '/manage/syslog',
      meta: {
        title: '操作日志管理',
        breadCrumb: [{ name: '平台管理' }, { name: '操作日志管理' }]
      },
      component: () => import('../views/manage/syslog/syslog.vue')
    }
  ]
};
