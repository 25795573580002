





















import { Vue, Component, Watch } from 'vue-property-decorator';
import { Mutation, namespace, State } from 'vuex-class';
const System = namespace('System');
@Component
export default class KTagInfo extends Vue {
  @State MENU_INDENT: any;
  @State BLANK_PAGE: any;
  @Mutation SWITCH_MENU_INDENT: any;
  @System.State activeTabs: any;
  @System.Mutation closeOtherTabs: any;
  @System.Mutation closeAllTabs: any;
  @System.Mutation removeActiveTabs: any;

  breadCrumb: any[] = [];
  activeKey: string = '';

  get IS_BLANK() {
    let path = this.$route.path;
    return this.BLANK_PAGE.includes(path);
  }

  created() {
    this.activeKey = this.$route.fullPath;
    this.breadCrumb = this.$route?.meta?.breadCrumb;
  }

  @Watch('$route')
  onRouteChange(to: any) {
    this.activeKey = to.fullPath;
    this.breadCrumb = to.meta?.breadCrumb;
  }

  changeTab(path: string) {
    this.$router.push(path);
  }

  onEdit(targetKey: any, action: any) {
    if (action === 'remove') {
      this.removeActiveTabs(targetKey);
    }
  }

  handleTabMenu(e: any) {
    if (e.key === 'other') {
      this.closeOtherTabs(this.$route.fullPath);
    }
    if (e.key === 'all') {
      this.closeAllTabs();
    }
  }
}
